import { useContext } from "react"
import { NavLink } from "react-router-dom"

import './SidebarItem.css'
import { LayoutContext } from "../../../../../../Contexts/GlobalContexts/LayoutContext";
import SidebarContext from "../../SidebarContext";
import { DataContext } from "../../../../../../Contexts/GlobalContexts/DataContext";

export function SidebarItem({ children, title, isPendingRequest }) {

    const { setShowSidebar } = useContext(SidebarContext)
    const { expandSidebar } = useContext(LayoutContext)

    const { pendingRequest } = useContext(DataContext)
    return (
        <div className="item relative" onClick={() => setShowSidebar(false)}>

            <NavLink to={
                '/main/' +
                (
                    title.includes(' ')
                        ?
                        title.replace(/\s/g, '')
                        :
                        title
                )
            } >
                {
                    isPendingRequest && pendingRequest?.length > 0 && <p className="absolute rounded-full text-xl  p-1 -top-4 border h-10 w-10 bg-yellow-500 text-black text-center right-0 z-[50000] ">{pendingRequest?.length}</p>
                }
                {children}
                {expandSidebar &&
                    <span>{title}</span>
                }
            </NavLink>
        </div>
    )
}