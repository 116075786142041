import React from 'react'

import { RiGroupLine, RiHomeOfficeLine, RiUser2Fill, RiUserShared2Line } from 'react-icons/ri';
import { ScreenHolder } from '../../../Partials/Layout/ScreenHolder/ScreenHolder';
import { NavCardList } from '../../../Partials/Layout/NavCardList/NavCardList';
import { NavCard } from '../../../Partials/Layout/NavCardList/NavCard/NavCard';
import { GiToken } from 'react-icons/gi';

function Dashboard() {
  return (
    <div>
      <ScreenHolder>
        <NavCardList numOfCards={"three"}>
          <NavCard cardName={"Working Capital"} navCardLink="/main/WorkingCapital">
            <RiHomeOfficeLine />
          </NavCard>
          <NavCard cardName={"Nano Asset"} navCardLink="/main/NanoAsset">
            <GiToken />
          </NavCard>
          <NavCard cardName={"Employees"} navCardLink="/main/employees">
            <RiGroupLine />
          </NavCard>

        </NavCardList>
      </ScreenHolder>

    </div>
  )
}

export default Dashboard