import { createContext, useState, useEffect } from "react";
import { useAxiosInstance } from './../../Utilities/Hooks/useAxiosInstance';

const DataContext = createContext({});

function DataContextProvider({ children }) {
    const [pendingRequest, setPendingRequest] = useState(null); // initialize state to null or empty array if necessary
    const axiosInstance = useAxiosInstance();

    const [toggleFetch, setToggleFetch] = useState(false);

    function triggerFetch() {
        setToggleFetch((prevState) => !prevState);
    }

    async function fetchPendingRequest() {
        try {
            const { data } = await axiosInstance.get(
                "employeeApp/protected/manageTokenRequest/getAllPendingRequest"
            );
            setPendingRequest(data?.data || []);
        } catch (error) {
            console.error("Error fetching pending requests", error);
        }
    }

    useEffect(() => {
        fetchPendingRequest(); // Fetch data when the component mounts
    }, [toggleFetch]); // Empty dependency array ensures this runs only once

    console.log(pendingRequest?.data)

    return (
        <DataContext.Provider
            value={{
                pendingRequest,
                setPendingRequest,
                toggleFetch,
                triggerFetch,
                setToggleFetch
            }}
        >
            {children}
        </DataContext.Provider>
    );
}

export { DataContextProvider, DataContext };
