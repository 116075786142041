// const exportToCSV = (investors) => {
//   const headers = ["Name", "Email Address", "Status"];
//   const rows = investors.map(investor => [
//     investor.name,
//     investor.email,
//     investor.isActive ? "Active" : "Inactive"
//   ]);
import { MdOutlineAssignmentInd } from "react-icons/md";
import { useContext, useMemo, useState } from "react";
import { AuthContext } from "../../../../../../Contexts/GlobalContexts/AuthContext";
import { useAxiosInstance } from "../../../../../../Utilities/Hooks/useAxiosInstance";
import { useGetInfo } from "antopolis-react-utils/hooks";
import BackButton from "../../../../../Partials/Elements/BackButton/BackButton";
import { CreateButton } from "../../../../../Partials/Layout/CRUD/CreateButton/CreateButton";
import { CRUDTable } from "../../../../../Partials/Layout/CRUD/CRUDTable/CRUDTable";
import { CRUDTableHeader } from "../../../../../Partials/Layout/CRUD/CRUDTableHeader/CRUDTableHeader";
import { CRUDth } from "../../../../../Partials/Layout/CRUD/CRUDth/CRUDth";
import { ShortTextCell } from "../../../../../Partials/Layout/CRUD/ShortTextCell/ShortTextCell";
import {
  CRUDButton,
  DeleteButton,
  EditButton,
} from "../../../../../Partials/Layout/CRUD/CRUDButtons/CRUDButtons";
import { BsFileEarmarkBarGraph } from "react-icons/bs";
import { useParams } from "react-router-dom";
import { Modal } from "../../../../../Partials/Elements/Modal/Modal";
import UnAssignInvestorUser from "./UnAssignInvestorUser";
import {
  MANAGE_INVESTOR_USERS_API,
  MANAGE_INVESTOR_USERS_INVESTORS_API,
  MANAGE_Investors_API,
} from "../../../../../../Utilities/APIs/APIs";
import CreateInvestorUser from "../CreateInvestorUser";
import AssignInvestorUser from "../AssignInvestorUser";

//   const csvContent =
//     "data:text/csv;charset=utf-8," +
//     [headers.join(","), ...rows.map(row => row.join(","))].join("\n");

//   const encodedUri = encodeURI(csvContent);
//   const link = document.createElement("a");
//   link.setAttribute("href", encodedUri);
//   link.setAttribute("download", "investors.csv");
//   document.body.appendChild(link);

//   link.click();
//   document.body.removeChild(link);
// };

function SingleInvestorUsersInvestors({ api }) {
  const [toggleFetch, setToggleFetch] = useState(false);
  //   const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const { employee } = useContext(AuthContext);
  const axiosInstance = useAxiosInstance();
  const [showDeleteSection, setShowDeleteSection] = useState(false);

  const { id: investorUserID } = useParams();

  const { info: investorUsersInvestors } = useGetInfo({
    axiosInstance: axiosInstance,
    api: api + "getAllInvestorOfInvestorUser/" + investorUserID,
    toggleFetch,
  });

  const { info: user } = useGetInfo({
    axiosInstance: axiosInstance,
    api:
      "api/" +
      MANAGE_INVESTOR_USERS_API +
      "getSingleInvestorUser/" +
      investorUserID,
    toggleFetch,
  });

  console.log(user);

  // const navigate = useNavigate();

  // const handleRowClick = (e, investor) => {
  //     const isButtonClick = e.target.closest(".wc_investor_action_buttons");
  //     if (!isButtonClick) {
  //         navigate("/main/investments/" + investor._id);
  //     }
  // };

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  const sortedInvestorUsersInvestors = useMemo(() => {
    if (!investorUsersInvestors) return [];
    return investorUsersInvestors
      .slice()
      .sort((a, b) => (a.investor.name || "").localeCompare(b.investor.name || ""));
  }, [investorUsersInvestors]);

  return (
    <div className="screen_wrapper">
      <div className="screen_header">
        <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
          <BackButton />
          <h1 className="screen_heading">{`${user?.name ? user.name : ""
            }'s Investors (${investorUsersInvestors?.length || 0} in total)`}</h1>
        </div>
        <div style={{ display: "flex", gap: "20px" }}>
          <CRUDButton handleClick={() => setShowUpdateForm(true)}>
            <p style={{ color: "#FFE09D", textAlign: "center" }}>
              <MdOutlineAssignmentInd size={35} />
            </p>
          </CRUDButton>
          {/* screenTopicSingular={"Investment User"}
                    setShowUpdateForm={setShowUpdateForm} */}

          {/* <CRUDButton handleClick={() => exportToCSV(sortedInvestors)}>
            <p style={{ fontSize: "16px", color: "#FFE09D", textAlign: "center" }}>Export To CSV</p>
          </CRUDButton> */}
        </div>
      </div>
      <CRUDTable>
        <CRUDTableHeader>
          <CRUDth align={"left"} th="Investor Name" />
          {/* <CRUDth th="Investment User" /> */}
          <CRUDth th="Actions" />
        </CRUDTableHeader>
        <tbody>
          {sortedInvestorUsersInvestors &&
            sortedInvestorUsersInvestors.map((investorUsersInvestor) => (
              <tr
                key={investorUsersInvestor._id}
              // onClick={(e) => handleRowClick(e, investorUsersInvestor)}
              >
                <td>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      justifyContent: "start",
                    }}
                  >
                    <img
                      style={{
                        height: "30px",
                        width: "30px",
                        borderRadius: "50%",
                      }}
                      src={
                        investorUsersInvestor?.investor?.image
                          ? process.env.REACT_APP_SPACES_URL +
                          investorUsersInvestor?.investor?.image
                          : `https://ui-avatars.com/api/?name=${investorUsersInvestor?.investor?.name}&background=random&length=1`
                      }
                      alt=""
                    />
                    <p>{investorUsersInvestor.investor.name}</p>
                  </div>
                </td>
                {/* <ShortTextCell text={investorUsersInvestor?.investorUser?.name} /> */}
                {/* <ShortTextCell
                                    text={investorUsersInvestor.isActive ? "Active" : "Inactive"}
                                /> */}
                <td className="action_buttons_wrapper">
                  <div className="action_buttons wc_investor_action_buttons">
                    {/* <EditButton
                                            setShowUpdateForm={setShowUpdateForm}
                                            targetID={investorUsersInvestor._id}
                                            setTargetID={setTargetID}
                                        />
                                        <CRUDButton handleClick={() => navigate(`/main/investmentsGraph/${investorUser._id}`)}>
                                            <BsFileEarmarkBarGraph size={25} />
                                        </CRUDButton> */}

                    <DeleteButton
                      setShowDeleteSection={setShowDeleteSection}
                      targetID={investorUsersInvestor._id}
                      setTargetID={setTargetID}
                      tooltipId="single-investor-userInvestor-archive-btn"
                      tooltipContent="Archive"
                    />
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </CRUDTable>
      {showUpdateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Assign`}
          setShowModalContent={setShowUpdateForm}
        >
          <AssignInvestorUser
            api={MANAGE_INVESTOR_USERS_API}
            employee={employee}
            setShowUpdateForm={setShowUpdateForm}
            triggerFetch={triggerFetch}
            targetID={user._id}
          />
        </Modal>
      )}{" "}
      {/* 

      {showUpdateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Update Investor`}
          setShowModalContent={setShowUpdateForm}
        >
          <UpdateInvestor
            api={MANAGE_Investors_API}
            employee={employee}
            setShowUpdateForm={setShowUpdateForm}
            targetID={targetID}
            triggerFetch={triggerFetch}
          />
        </Modal>
      )} */}
      {showDeleteSection && (
        <Modal
          extraClass={"small"}
          modalHeading={`Delete Investor`}
          setShowModalContent={setShowDeleteSection}
        >
          <UnAssignInvestorUser
            api={MANAGE_INVESTOR_USERS_INVESTORS_API}
            employee={employee}
            setShowDeleteSection={setShowDeleteSection}
            targetID={targetID}
            triggerFetch={triggerFetch}
          />
        </Modal>
      )}
    </div>
  );
}

export default SingleInvestorUsersInvestors;
