import { useContext, useEffect, useState } from "react";
import { useAxiosInstance } from "../../../../../Utilities/Hooks/useAxiosInstance";
import { useNavigate, useParams } from "react-router-dom";
import { MANAGE_Tokens_API } from "../../../../../Utilities/APIs/APIs";
import BackButton from "../../../../Partials/Elements/BackButton/BackButton";
import { CreateButton } from "../../../../Partials/Layout/CRUD/CreateButton/CreateButton";
import { CRUDTable } from "../../../../Partials/Layout/CRUD/CRUDTable/CRUDTable";
import { CRUDTableHeader } from "../../../../Partials/Layout/CRUD/CRUDTableHeader/CRUDTableHeader";
import { CRUDth } from "../../../../Partials/Layout/CRUD/CRUDth/CRUDth";
import { ShortTextCell } from "../../../../Partials/Layout/CRUD/ShortTextCell/ShortTextCell";
import { CRUDButton, EditButton } from "../../../../Partials/Layout/CRUD/CRUDButtons/CRUDButtons";
import { Modal } from "../../../../Partials/Elements/Modal/Modal";
import { AuthContext } from "../../../../../Contexts/GlobalContexts/AuthContext";
import UpdateToken from "./UpdateToken";
import CreateToken from "./CreateToken";
import { FaFileZipper } from "react-icons/fa6";



function Tokens({ api }) {
  const [toggleFetch, setToggleFetch] = useState(false);
  const [tokens, setTokens] = useState(null);
  const { id: investorsId } = useParams();

  const [targetID, setTargetID] = useState(null);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  // const [showDeleteSection, setShowDeleteSection] = useState(false);

  const { employee } = useContext(AuthContext);
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetTokens() {
      const { data } = await axiosInstance.get(
        MANAGE_Tokens_API + "getAllTokens/"
      );
      setTokens(data.data);
    }
    fetchAndSetTokens();
  }, [axiosInstance, investorsId, targetID, toggleFetch]);

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  const navigate = useNavigate()

  return (
    <div className="screen_wrapper">
      <div className="screen_header">
        <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
          <BackButton />
          <h1 className="screen_heading">{`Nano Assets (${tokens?.length} in total)`}</h1>
        </div>
        <CreateButton
          screenTopicSingular={"Nano Assets"}
          setShowCreateForm={setShowCreateForm}
        />
      </div>
      <CRUDTable>
        <CRUDTableHeader>
          <CRUDth th="Asset Name" />
          {/* <CRUDth th="Company Name" /> */}
          <CRUDth th="ROI (%)" />
          <CRUDth th="Tenure (Days)" />
          <CRUDth th="Total Tokens" />
          <CRUDth th="Actions" />
        </CRUDTableHeader>
        <tbody>
          {tokens &&
            tokens?.map((token) => (
              <tr key={token._id}>
                <ShortTextCell text={token.tokenName} />
                {/* <ShortTextCell text={token.company.name} /> */}
                <ShortTextCell text={` ${token.roi}%`} />
                <ShortTextCell text={` ${token.tenure}`} />
                <ShortTextCell text={` ${token.totalIssuedTokens}`} />
                <td className="action_buttons_wrapper">
                  <div
                    style={{ justifyContent: "center" }}
                    className="action_buttons"
                  >
                    <EditButton
                      setShowUpdateForm={setShowUpdateForm}
                      targetID={token._id}
                      setTargetID={setTargetID}
                    />
                    <CRUDButton handleClick={() => navigate(`/main/nanoAssetImages/${token._id}`)}>
                      <FaFileZipper size={25} />    
                    </CRUDButton>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </CRUDTable>

      {showUpdateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Update Nano Asset`}
          setShowModalContent={setShowUpdateForm}
        >
          <UpdateToken
            api={MANAGE_Tokens_API}
            employee={employee}
            setShowEditSection={setShowUpdateForm}
            targetID={targetID}
            triggerFetch={triggerFetch}
          />
        </Modal>
      )}

      {showCreateForm && (
        <Modal
          extraClass={"medium"}
          modalHeading={`Create New Nano Asset`}
          setShowModalContent={setShowCreateForm}
        >
          <CreateToken
            api={MANAGE_Tokens_API}
            employee={employee}
            setShowEditSection={setShowCreateForm}
            targetID={investorsId}
            triggerFetch={triggerFetch}
          />
        </Modal>
      )}
    </div>
  );
}

export default Tokens;
