import { useAxiosInstance } from "../../../../../Utilities/Hooks/useAxiosInstance";
import { MANAGE_TOKEN_REQUESTS_API } from "../../../../../Utilities/APIs/APIs";

function RejectTokenRequest({
    targetID,
    setShowRejectSection,
    triggerFetch,

}) {
    const axiosInstance = useAxiosInstance();

    async function handleDelete() {
        const response = await axiosInstance.post(
            MANAGE_TOKEN_REQUESTS_API + "/rejectTokenRequest/" + targetID,
        );

        console.log(response, "response")

        if (response) {
            setShowRejectSection(false);
            triggerFetch();
        }
    }
    return (
        <div className="modal_delete_content flex flex-col gap-4 items-center justify-center">
            <p className="text-gray-200">Are you sure you want to reject this request?</p>

            <div className="modal_group_buttons">
                <button
                    className="submit"
                    onClick={() => {
                        handleDelete();
                    }}
                >
                    Reject
                </button>

                <button
                    className="cancel"
                    onClick={() => {
                        setShowRejectSection(false);
                    }}
                >
                    Cancel
                </button>
            </div>
        </div>
    );
}

export default RejectTokenRequest;
